export function mobileScrollMenu() {
	//Mobile swipe set width
	if ($('.mobile-swipe-pagelist').length > 0)
	{
		const el = $('.mobile-swipe-holder .pagelist-link');
		let holderWidth = 0;

		el.each(function() {
			let itemWidth = $(this).width() + 20;
			holderWidth = holderWidth + itemWidth;
		});


		$('.mobile-swipe-holder').width(holderWidth + 1);
	}
}