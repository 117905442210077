export function swiperHandler() {
	//Need to iterate over sliders so it doesnt bug out with multiple instances
	$('.swiper-container').each(function(index, element){
		var $this = $(this);
		$this.addClass('instance-' + index);
		$this.find('.js-prev-slide').addClass('js-prev-slide-' + index);
		$this.find('.js-next-slide').addClass('js-next-slide-' + index);
		var swiper = new Swiper('.instance-' + index, {
			// Optional parameters
			direction: 'horizontal',
			effect: 'fade', 
			speed: 100,   
			// Navigation arrows
			prevButton: '.js-prev-slide-' + index,
			nextButton: '.js-next-slide-' + index,
			breakpoints: {
				500: {
					effect: 'slide',
					speed: 200
				}
			}
		});
	});
}