export function employeeSearch() {
	const searchField = document.getElementById('employeeSearch');

	searchField.onkeyup = function () {

		let limit = 3;
		let value = this.value;
		const employees = document.querySelectorAll('.employee-item');

		employees.forEach(function (employee) {

			employee.classList.add('hidden');
			let employeeData = {
				"name": employee.dataset.name,
				"tag": employee.dataset.tag,
				"city": employee.dataset.city,
				"title": employee.dataset.title,
				"cat": employee.dataset.cat,
				"visible": false
			}

			// function generateRegex (input) {
			// 	var string = '^', arr = input.trim().split(' ');

			// 	arr.forEach(function (chars, i) {
			// 	  string += chars + '\\w*' + (arr.length - 1 > i ? '\\s+' : '');
			// 	});

			// 	return new RegExp(string, 'i');
			// }


            Object.keys(employeeData).forEach(function(key) {
                let dataValue = employeeData[key];

                if(typeof dataValue === 'string') {
                    dataValue = dataValue.toLowerCase();
                    var result = dataValue.match(new RegExp('\\b' + value, 'i'));
                    employee.classList.remove('visible');
                    if(result != null && result.length >= 1) {
                        employeeData.visible = true;
                    }
                }
            })

            if(employeeData.visible == true) {
				employee.classList.add('visible');
				employee.classList.remove('no-show');
				employee.classList.remove('hidden');
			}

		});

		let displayedEmployees = document.querySelectorAll('.employee-item.visible');
		// const btn = document.querySelector('.js-show-all-contacts');
		// let i = 0;

		// displayedEmployees.forEach(function(item) {
		// 	item.classList.remove('no-show');
		// 	i++;
		// 	if (displayedEmployees.length > limit && i > limit) {
		// 		item.classList.add('no-show');
		// 	}
		// });

		const noMatch = document.getElementById('no-match');

		if (displayedEmployees.length > 0 && displayedEmployees.length < limit) {
			// btn.style.visibility = 'hidden';
			noMatch.style.display = 'none';
		} else if (displayedEmployees.length < 1) {
			noMatch.style.display = 'block';
		} else {
			// btn.style.visibility = 'visible';
			noMatch.style.display = 'none';
		}

		// btn.addEventListener('click', function(){

		// 	btn.style.visibility = 'hidden';

		// 	displayedEmployees.forEach(function(item) {
		// 		item.classList.remove('no-show');
		// 	});

		// });

	}

	searchField.onkeyup();

}