export function accordionHandler()
{
    $('.js-accordion-button').on('click', function() {
        const accordionContent = $(this).parent().next();
        accordionContent.toggleClass('show');

        if (accordionContent.hasClass('show')) {
            $(this).find($('span')).text('Dölj');
        } else {
            $(this).find($('span')).text('Visa');
        }
    });
}
