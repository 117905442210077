export function forms() {
	//Form weirdness
	var $form = $('#search-form');
	$('.js-do-search').click(function() {
		$form.submit();
	});

	$('.js-focus-search').click(function() {
		/* $('#search').focus(); */
		$('.nav-search').addClass('active');
	});

	$('.js-show-search').click(function() {
		if ($('.nav-search').hasClass('active')) {
			$('.nav-search').removeClass('active');
		} else {
			$('.nav-search').addClass('active');
		}
	});

	$('.js-scroll-to-top').click(function() {
		$("html, body").animate({ scrollTop: 0 }, 0);
	});

	let textInput = $('.frm_style_formidable-style.with_frm_style .form-field input:not([type=file]), .frm_style_formidable-style.with_frm_style .form-field textarea, .frm_style_formidable-style.with_frm_style .form-field select');
	textInput.focus(function() {
		$(this).siblings().addClass('focus');
	});
	textInput.blur(function() {
		$(this).siblings().removeClass('focus');
	});
	//FAQ
	$('.faq-item-heading').click(function() {
		console.log("hej");
		if ( $(this).parent().hasClass('open') )
		{
			$(this).parent().removeClass('open');
			return false;
		}

		$('.faq-item-heading').parent().removeClass('open');

		$(this).parent().addClass('open');

	});
}