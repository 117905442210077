var List = require('list.js');

module.exports = new function() {

	$('.js-open-contact-search').click(function(event) {
		event.stopPropagation();
		$(this).toggleClass('active');
		$('.contact-search-box').fadeToggle(0);
		$('.contact-search').val('');
	});
	$('.contact-search-box').click(function(event){
		event.stopPropagation();
	});
	$(document).click(function(){
		$('.contact-search-box').hide();
		$('.js-open-contact-search').removeClass('active');
		$('.contact-search').val('');
	});

	//Get all contacts as JSON
	var allContacts  = {}
	if( $('#contactObject').length ){
		allContacts = JSON.parse($('#contactObject').html());
	}

	//List.js settings
	var options = {
		valueNames: [
			'name',
			'id',
			'title',
			'mobile-content',
			'landline-content',
			'email-content',
			{ name: 'mobile', attr: 'href' },
			{ name: 'landline', attr: 'href' },
			{ name: 'email', attr: 'href' },
			{ name: 'image', attr: 'src' }
		],
		item: 'contact-item'
	};

	var values = [];

	for ( var i in allContacts) {
		values.push(allContacts[i]);
	}
	var $searchField = $('.contact-search');

	function init() {

		$searchField.focus();
		const contactList = new List('contact-list', options, values);

		//Hide all contacts because we're doing this backwards
		contactList.show(); // Calling 'show' to hide stuff seems confusing, but it returns an empty list if no parameters are given.

		//Show matching items when the user has completed a keypress
		contactList.on('searchComplete',function() {

			for (var i in contactList.matchingItems) {

				const item = contactList.matchingItems[i];

				if( item.hasOwnProperty('show') )
				{
					item.show();
				}
			}

			if ($searchField.val() == '')
			{
				contactList.show();
			}

			if ( $('.list li').length == 0)
			{
				$('#noResult').show();
			}
			else
			{
				$('#noResult').hide();
			}
		});
	}

	return {
		init: function() {
			init();
		}
	}
}