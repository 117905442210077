import flickity from 'flickity';



  
export function vueSlider() {
    const slider = document.querySelectorAll('.js-slider');
    let groupCells = false;
    if ( slider != undefined ) {
        for (let i = 0; i < slider.length; i++) {
            let flkty = new flickity(slider[i], {
                cellAlign: 'left',
                pageDots: false,
                contain: true,
                prevNextButtons: true,
                wrapAround: false,
                selectedAttraction: 0.01,
                friction: 0.15,
                // draggable: false,
                // groupCells: groupCells,
            });
        }
    }
}
