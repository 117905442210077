export function articleHash() {

	if ( $('.blue-line-block').length || $('.black-line-block').length )
	{	

		$('.blue-line-block, .black-line-block').attr('id', 'hashScroll');

		if ( window.location.hash )
		{
			$('html, body').animate({
				scrollTop: $('#hashScroll').offset().top - 40
			}, 5);
		}

	}

}